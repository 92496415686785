<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                :to="{name: 'add-blog'}"
                variant="primary"
              >
                <span class="text-nowrap">Add New Post</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row class="blog-list-wrapper">
      <b-col
        v-for="post in paginatedItems"
        :key="post.id"
        md="4"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link class="img-blog">
            <b-img
              :src="post.image"
              class="card-img-top blog-img"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                class="blog-title-truncate text-body-heading"
              >
                {{ post.title }}
              </b-link>
            </b-card-title>
            <b-card-text class="blog-content-truncate">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="sanitizeHtml(post.description.slice(0, 150))" />
            </b-card-text>
            <b-card-text class="blog-content-truncate">
              <span
                v-if="post.language"
              >
                <b-badge variant="dark">{{ post.language.name }}</b-badge>
              </span>
            </b-card-text>
            <hr>
            <template>
              <b-dropdown
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!-- <b-dropdown-item>
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">View</span>
                </b-dropdown-item> -->

                <b-dropdown-item :to="{ name: 'edit-blog', params: { id: post.id }}">
                  <feather-icon icon="EditIcon" />
                  <span> Edit </span>
                </b-dropdown-item>
                <b-dropdown-item @click="deletePost(post.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            align="right"
            first-number
            last-number
            class="mb-0 mt-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BRow, BCol, BCard, BFormInput, BCardText, BCardTitle, BMedia, BAvatar, BMediaAside, BButton, BDropdown, BDropdownItem,
  BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue'
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, onUnmounted,
} from '@vue/composition-api'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import sanitizeHtml from 'sanitize-html'

Vue.use(VueSweetalert2)

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      totalRows: null,
      isBusy: false,
      currentPage: 1,
      perPage: 9,
      posts: [],
      paginatedItems: [],
    }
  },
  setup() {
    const currentPage = ref(1)
    const perPage = ref(6)

    return {
      currentPage,
      perPage,
    }
  },
  created() {
    this.myProvider()
  },
  mounted() {
    this.paginate(this.perPage, 0)
  },
  methods: {
    sanitizeHtml,
    myProvider() {
      const promis = axios.get('get/blog')
      return promis.then(res => {
        const items = res.data.success.data
        this.paginatedItems = res.data.success.data
        this.totalRows = res.data.success.total
        this.posts = items
        return items
      // eslint-disable-next-line no-unused-vars
      }).finally(() => {
        this.loading = false
      // eslint-disable-next-line no-unused-vars
      }).catch(error => [])
    },
    // eslint-disable-next-line camelcase
    paginate(page_size, page_number) {
      const itemsToParse = this.posts
      this.paginatedItems = itemsToParse.slice(
        // eslint-disable-next-line camelcase
        page_number * page_size,
        // eslint-disable-next-line camelcase
        (page_number + 1) * page_size,
      )
    },
    deletePost(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`delete/blog/${id}`).then(res => {
            this.myProvider()
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
    onPageChanged(page) {
      // this.paginate(this.perPage, page - 1)
      const promis = axios.get(`get/blog?page=${page}`)
      return promis.then(res => {
        const items = res.data.success.data
        this.paginatedItems = res.data.success.data
        this.totalRows = res.data.success.total
        this.posts = items
        return items
      // eslint-disable-next-line no-unused-vars
      }).finally(() => {
        this.loading = false
      // eslint-disable-next-line no-unused-vars
      }).catch(error => [])
    },
  },
}
</script>

<style scopped>
.img-blog {
    width: fit-content;
    align-self: center;
}
</style>
